
export const PAGE_ACTIONS = {
    CHANGE_CURRENT_PAGE: "CHANGE_CURRENT_PAGE",
    CHANGE_AUDIO_SRC: "CHANGE_AUDIO_SRC",
    SET_IS_AUDIO_PLAYING: "SET_IS_AUDIO_PLAYING",
    
};

export const LOGIN_ACTIONS = {
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT'
}

export const PLAYER_SETUP = {
    NUM_PLAYERS: 'NUM_PLAYERS',
    CHANGE_PLAYER_ATTRIBUTE: 'CHANGE_PLAYER_ATTRIBUTE',
    SET_USER_IMAGE: 'SET_USER_IMAGE'
}